<template>
	<div class="wrapper page-playbacks">
		<div class="web_body">
			<div class="playback">
				<div class="playback-tabs">
					<a href="javascript:;" :class="{ active: activeTab == item.key }" v-for="item in tabs"
						:key="item.key" @click="changeTab(item)">{{ item.text }}</a>
				</div>
				<div v-if="lists.total > 0">
					<div class="playback-body">
						<div class="playback-item" v-for="item in lists.data" :key="item.match_id">
							<div class="mdatetime">
								<span class="date">{{ item.match_time.split(' ')[0] }}</span>
								<span class="time">{{ item.match_time.split(' ')[1] }}</span>
							</div>
							<div class="mtitle">{{ item.match_title }}</div>
							<div class="teamname hometeam">{{ item.home_team_name }}</div>
							<div><img :src="item.home_avatar" class="teamlogo" /></div>
							<div class="score">{{ item.home_score }} vs {{ item.away_score }}</div>
							<div><img :src="item.away_avatar" class="teamlogo" /></div>
							<div class="teamname awayteam">{{ item.away_team_name }}</div>
							<div class="buttons">
								<a :href="`/playback/${item.video_type}/${item.match_id}`">录像/锦集</a>
							</div>
						</div>
					</div>
					<div class="playback-pagination">
						<a-pagination :current-page="query.page" :page-size="10" layout="prev, pager, next, jumper"
							:total="lists.total" @change="onPageChange"></a-pagination>
					</div>
				</div>
				<div class="playback-empty" v-else-if="!lists.loading"><img src="../assets/img/noplayback.png" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'playbacks',
	components: {
	},
	data() {
		return {
			tabs: [
				{
					key: 'qb',
					text: '全部'
				},
				{
					key: 'nba',
					text: 'NBA'
				},
				{
					key: 'xj',
					text: '西甲'
				},
				{
					key: 'cba',
					text: 'CBA'
				},
				{
					key: 'yc',
					text: '英超'
				},
				{
					key: 'yj',
					text: '意甲'
				},
				{
					key: 'dj',
					text: '德甲'
				},
				{
					key: 'zc',
					text: '中超'
				},
				{
					key: 'fj',
					text: '法甲'
				}
			],
			query: {
				page: 1
			},
			lists: {
				loading: true,
				total: 0,
				data: []
			}
		};
	},
	computed: {
		activeTab() {
			return this.$route.params.tab || 'qb';
		}
	},
	beforeRouteUpdate(to, from, next) {
		console.info('beforeRouteUpdate', to.params)
		next()
		console.info('beforeRouteUpdate', this.activeTab)
		this.getPlayback()
	},
	created: function () {
		this.getPlayback();
	},
	methods: {
		getPlayback() {
			this.lists.loading = true;
			this.$loading();
			console.info('getPlayback', this.activeTab)
			this.$api.get(`/videos/${this.activeTab}`, { params: this.query }).then(res => {
				if (res.code == 200) {
					this.lists.data = res.data.list || [];
					this.lists.total = res.data.count || 0;
					this.$nextTick(() => {
						this.lists.loading = false;
						this.$loadend();
					});
				}
			});
		},
		changeTab(item) {
			this.query.page = 1;
			if (this.activeTab != item.key) {
				console.info('replace')
				this.$router.replace(`/playback/${item.key}`);
			} else {
				this.getPlayback();
			}
		},
		onPageChange(page) {
			console.info('onPageChange', page)
			this.query.page = page;
			this.getPlayback();
		}
	}
};
</script>

<style lang="less">
@import url('~@/assets/less/variables.less');
@banner-height: 552px;
@banner-bgcolor: #f7f7f8;
@banner-aside-bgcolor: rgba(0, 0, 0, 0.5);

.page-playback {
	background: #f7f7f8;

	.banner {
		margin-top: 26px;
	}
}

.playback {
	width: 1200px;
	margin: 0 auto;
	padding: 26px 0;

	&-tabs {
		height: 58px;
		box-shadow: 0px 0px 12px 0px rgba(0, 40, 95, 0.06);
		display: flex;
		flex-direction: row;
		background-color: #ffffff;

		a {
			position: relative;
			line-height: 58px;
			text-align: center;
			padding: 0 36px;
			font-size: 18px;
			color: #001e47;
			display: block;

			&:hover,
			&.active {
				color: @primary-color;

				&::after {
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					width: 45px;
					height: 3px;
					background: @primary-color;
					border-radius: 2px;
				}
			}
		}
	}

	&-body {
		margin-top: 1px;
	}

	&-item {
		background-color: #ffffff;
		border: 0;
		height: 84px;
		border-bottom: #f1f5fa 1px solid;
		font-size: 16px;
		font-family: Source Han Sans CN;
		display: flex;
		flex-direction: row;
		align-items: center;

		.mdatetime {
			width: 108px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			color: #666666;
			line-height: 1;

			.time {
				margin-top: 10px;
				font-size: 14px;
				font-weight: 500;
			}
		}

		.mtitle {
			text-align: center;
			width: 150px;
			color: #666666;
		}

		.teamlogo {
			width: 36px;
			height: 36px;
			vertical-align: middle;
		}

		.teamname {
			width: 208px;
			box-sizing: border-box;
			font-family: Source Han Sans CN;
		}

		.hometeam {
			text-align: right;
			padding-right: 54px;
		}

		.awayteam {
			text-align: left;
			padding-left: 54px;
		}

		.score {
			text-align: center;
			width: 240px;
			font-weight: 500;
			font-size: 20px;
			color: #ff0000;
		}

		.buttons {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;

			a {
				text-align: center;
				width: 110px;
				line-height: 34px;
				background: #FFB400;
				border-radius: 6px;
				color: #ffffff;
			}
		}
	}

	&-pagination {
		padding: 50px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	&-empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 100px 0;

		img {
			width: 446px;
		}
	}
}

@media screen and (max-width: 1440px) {
	.playback {
		width: 1060px
	}

	.playback-tabs a {
		padding: 0 30px;
	}

	.playback-item {
		.score {
			width: 180px;
		}
	}
}
</style>
